import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Dwars door Vlaanderen / A travers les Flandres",
      edition: 2022,
      what: "Race data",
      updated: "2022-03-29 16:05:44",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00c3\u00abn Team",
        teamNationCode: "FRA",
      },
      21803: {
        teamId: "21803",
        teamUciCode: "AST",
        teamName: "Astana Qazaqstan Team",
        teamNationCode: "KAZ",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21809: {
        teamId: "21809",
        teamUciCode: "QST",
        teamName: "Quick-Step Alpha Vinyl Team",
        teamNationCode: "BEL",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21814: {
        teamId: "21814",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21823: {
        teamId: "21823",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21827: {
        teamId: "21827",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21830: {
        teamId: "21830",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21843: {
        teamId: "21843",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      21845: {
        teamId: "21845",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21850: {
        teamId: "21850",
        teamUciCode: "BBK",
        teamName: "B&B Hotels-KTM",
        teamNationCode: "FRA",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        teamNationCode: "BEL",
      },
      21865: {
        teamId: "21865",
        teamUciCode: "ARK",
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        teamNationCode: "FRA",
      },
      21868: {
        teamId: "21868",
        teamUciCode: "TEN",
        teamName: "TotalEnergies",
        teamNationCode: "FRA",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22290: {
        teamId: "22290",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      22480: {
        teamId: "22480",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      23137: {
        teamId: "23137",
        teamUciCode: "BWB",
        teamName: "Bingoal Pauwels Sauces WB",
        teamNationCode: "BEL",
      },
      23398: {
        teamId: "23398",
        teamUciCode: "SVB",
        teamName: "Sport Vlaanderen-Baloise",
        teamNationCode: "BEL",
      },
    },
    riders: {
      7580: {
        id: 7580,
        startno: 34,
        firstName: "Gijs",
        lastName: "Van Hoecke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-11-12",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      45356: {
        id: 45356,
        startno: 225,
        firstName: "Aaron",
        lastName: "Van Poucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-04-04",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      12800: {
        id: 12800,
        startno: 213,
        firstName: "Alexis",
        lastName: "Gougeard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-03-05",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      39286: {
        id: 39286,
        startno: 174,
        firstName: "Alexander",
        lastName: "Konychev",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-07-25",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      64658: {
        id: 64658,
        startno: 124,
        firstName: "Marijn",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-07-19",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      10382: {
        id: 10382,
        startno: 66,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-30",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      16457: {
        id: 16457,
        startno: 22,
        firstName: "Stijn",
        lastName: "Steels",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-08-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      71217: {
        id: 71217,
        startno: 6,
        firstName: "Ben",
        lastName: "Turner",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-05-29",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      75603: {
        id: 75603,
        startno: 84,
        firstName: "Yevgeniy",
        lastName: "Fedorov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "2000-02-16",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      27232: {
        id: 27232,
        startno: 246,
        firstName: "Anders",
        lastName: "Skaarseth",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1995-05-07",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      16144: {
        id: 16144,
        startno: 76,
        firstName: "Frederik",
        lastName: "Frison",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-07-28",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      52665: {
        id: 52665,
        startno: 143,
        firstName: "Julius",
        lastName: "Johansen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1999-09-13",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      18260: {
        id: 18260,
        startno: 105,
        firstName: "Nils",
        lastName: "Politt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-03-06",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      37748: {
        id: 37748,
        startno: 24,
        firstName: "Fabio",
        lastName: "Jakobsen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-08-31",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      38000: {
        id: 38000,
        startno: 232,
        firstName: "Stanislaw",
        lastName: "Aniolkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-01-20",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      44543: {
        id: 44543,
        startno: 177,
        firstName: "Kelland",
        lastName: "O'Brien",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-05-22",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      1581: {
        id: 1581,
        startno: 151,
        firstName: "Sep",
        lastName: "Vanmarcke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-07-28",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      27307: {
        id: 27307,
        startno: 164,
        firstName: "Alex",
        lastName: "Aranburu",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-09-19",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      16646: {
        id: 16646,
        startno: 104,
        firstName: "Ryan",
        lastName: "Mullen",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1994-08-07",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      16672: {
        id: 16672,
        startno: 51,
        firstName: "Mathieu",
        lastName: "van der Poel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-01-19",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      16724: {
        id: 16724,
        startno: 131,
        firstName: "Stefan",
        lastName: "K\u00c3\u00bcng",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-11-16",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      8076: {
        id: 8076,
        startno: 27,
        firstName: "Bert",
        lastName: "Van Lerberghe",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-09-29",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      54613: {
        id: 54613,
        startno: 221,
        firstName: "Alex",
        lastName: "Colman",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-22",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      63050: {
        id: 63050,
        startno: 193,
        firstName: "Matis",
        lastName: "Louvel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-07-19",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      14717: {
        id: 14717,
        startno: 86,
        firstName: "Davide",
        lastName: "Martinelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-31",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      38146: {
        id: 38146,
        startno: 186,
        firstName: "Joris",
        lastName: "Nieuwenhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-11",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      45363: {
        id: 45363,
        startno: 52,
        firstName: "Jasper",
        lastName: "Philipsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-03-02",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      20822: {
        id: 20822,
        startno: 231,
        firstName: "Arjen",
        lastName: "Livyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-01",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      16143: {
        id: 16143,
        startno: 214,
        firstName: "Julien",
        lastName: "Morice",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-07-20",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      46186: {
        id: 46186,
        startno: 212,
        firstName: "Luca",
        lastName: "Mozzato",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-15",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      31: {
        id: 31,
        startno: 201,
        firstName: "Edvald",
        lastName: "Boasson-Hagen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-05-17",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      37466: {
        id: 37466,
        startno: 72,
        firstName: "C\u00c3\u00a9dric",
        lastName: "Beullens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-01-27",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      4669: {
        id: 4669,
        startno: 26,
        firstName: "Zdenek",
        lastName: "Stybar",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1985-12-11",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      49662: {
        id: 49662,
        startno: 197,
        firstName: "Connor",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-10-30",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      6413: {
        id: 6413,
        startno: 36,
        firstName: "Bob",
        lastName: "Jungels",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-09-22",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      7910: {
        id: 7910,
        startno: 127,
        firstName: "Thomas",
        lastName: "Scully",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-01-14",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      120: {
        id: 120,
        startno: 91,
        firstName: "Heinrich",
        lastName: "Haussler",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1984-02-25",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      1276: {
        id: 1276,
        startno: 116,
        firstName: "Jelle",
        lastName: "Wallays",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-05-11",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      82253: {
        id: 82253,
        startno: 242,
        firstName: "William",
        lastName: "Blume Levy",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2001-01-14",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      1013: {
        id: 1013,
        startno: 181,
        firstName: "John",
        lastName: "Degenkolb",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-07",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      22133: {
        id: 22133,
        startno: 44,
        firstName: "Timo",
        lastName: "Roosen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-01-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      45992: {
        id: 45992,
        startno: 11,
        firstName: "Tadej",
        lastName: "Pogacar",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1998-09-21",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      6246: {
        id: 6246,
        startno: 102,
        firstName: "Marco",
        lastName: "Haller",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-04-01",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      13898: {
        id: 13898,
        startno: 194,
        firstName: "Christophe",
        lastName: "Noppe",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-11-29",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      16752: {
        id: 16752,
        startno: 111,
        firstName: "Bryan",
        lastName: "Coquard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-04-25",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      28781: {
        id: 28781,
        startno: 81,
        firstName: "Gianni",
        lastName: "Moscon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-20",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      7723: {
        id: 7723,
        startno: 114,
        firstName: "Wesley",
        lastName: "Kreder",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1990-11-04",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      4317: {
        id: 4317,
        startno: 137,
        firstName: "Tobias",
        lastName: "Ludvigsson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1991-02-22",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      31612: {
        id: 31612,
        startno: 33,
        firstName: "Lawrence",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-08-28",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      45371: {
        id: 45371,
        startno: 224,
        firstName: "Jens",
        lastName: "Reynders",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-05-25",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      16637: {
        id: 16637,
        startno: 206,
        firstName: "Anthony",
        lastName: "Turgis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-05-16",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      3147: {
        id: 3147,
        startno: 144,
        firstName: "Andrea",
        lastName: "Pasqualon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-01-02",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      38301: {
        id: 38301,
        startno: 233,
        firstName: "Mathijs",
        lastName: "Paasschens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-03-18",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      2317: {
        id: 2317,
        startno: 57,
        firstName: "Julien",
        lastName: "Vermote",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-07-26",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      18574: {
        id: 18574,
        startno: 236,
        firstName: "Ludovic",
        lastName: "Robeet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-05-22",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      828: {
        id: 828,
        startno: 5,
        firstName: "Ben",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-11-05",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      163: {
        id: 163,
        startno: 95,
        firstName: "Yukiya",
        lastName: "Arashiro",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1984-09-22",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      80276: {
        id: 80276,
        startno: 245,
        firstName: "Martin",
        lastName: "Urianstad",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-02-06",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      53489: {
        id: 53489,
        startno: 75,
        firstName: "Brent",
        lastName: "Van Moer",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-01-12",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      6309: {
        id: 6309,
        startno: 123,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      6414: {
        id: 6414,
        startno: 96,
        firstName: "Jasha",
        lastName: "S\u00c3\u00bctterlin",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-04",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      7724: {
        id: 7724,
        startno: 1,
        firstName: "Dylan",
        lastName: "Van Baarle",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-05-21",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      361: {
        id: 361,
        startno: 202,
        firstName: "Maciej",
        lastName: "Bodnar",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1985-03-07",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      2327: {
        id: 2327,
        startno: 176,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      3095: {
        id: 3095,
        startno: 147,
        firstName: "Kevin",
        lastName: "Van Melsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-04-01",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      333: {
        id: 333,
        startno: 163,
        firstName: "Imanol",
        lastName: "Erviti",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-11-15",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      3277: {
        id: 3277,
        startno: 101,
        firstName: "Sam",
        lastName: "Bennett",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1990-10-16",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      77102: {
        id: 77102,
        startno: 47,
        firstName: "Mick ",
        lastName: "Van Dijke",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-03-15",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      46157: {
        id: 46157,
        startno: 122,
        firstName: "Stefan",
        lastName: "Bissegger",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-09-13",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      1970: {
        id: 1970,
        startno: 23,
        firstName: "Tim",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-03-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      37434: {
        id: 37434,
        startno: 217,
        firstName: "Jordi",
        lastName: "Warlop",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-06-04",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      3045: {
        id: 3045,
        startno: 145,
        firstName: "Adrien",
        lastName: "Petit",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-09-26",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      65024: {
        id: 65024,
        startno: 85,
        firstName: "Michele",
        lastName: "Gazzoli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-04",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      7720: {
        id: 7720,
        startno: 171,
        firstName: "Dylan",
        lastName: "Groenewegen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-06-21",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      82912: {
        id: 82912,
        startno: 182,
        firstName: "Kevin",
        lastName: "Vermaerke",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2000-10-16",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      16786: {
        id: 16786,
        startno: 41,
        firstName: "Tiesj",
        lastName: "Benoot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-03-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      37782: {
        id: 37782,
        startno: 227,
        firstName: "Aaron",
        lastName: "Verwilst",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-05-02",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      32609: {
        id: 32609,
        startno: 216,
        firstName: "J\u00c3\u00a9r\u00c3\u00a9my",
        lastName: "Lecroq",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-04-07",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      777: {
        id: 777,
        startno: 203,
        firstName: "Daniel",
        lastName: "Oss",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-01-13",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      406: {
        id: 406,
        startno: 141,
        firstName: "Alexander",
        lastName: "Kristoff",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-07-05",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      19274: {
        id: 19274,
        startno: 71,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      1937: {
        id: 1937,
        startno: 155,
        firstName: "Guillaume",
        lastName: "Boivin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1989-05-25",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      93347: {
        id: 93347,
        startno: 132,
        firstName: "Lewis",
        lastName: "Askey",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-05-04",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      3930: {
        id: 3930,
        startno: 63,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      57958: {
        id: 57958,
        startno: 244,
        firstName: "Erik",
        lastName: "Resell",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-09-28",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      27331: {
        id: 27331,
        startno: 112,
        firstName: "Piet",
        lastName: "Allegaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-01-20",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      16610: {
        id: 16610,
        startno: 184,
        firstName: "S\u00c3\u00b8ren Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-08-10",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      37756: {
        id: 37756,
        startno: 167,
        firstName: "Max",
        lastName: "Kanter",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-22",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      37308: {
        id: 37308,
        startno: 43,
        firstName: "Pascal",
        lastName: "Eenkhoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-02-08",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      37286: {
        id: 37286,
        startno: 117,
        firstName: "Andr\u00c3\u00a9",
        lastName: "Carvalho",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1997-10-31",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      65447: {
        id: 65447,
        startno: 56,
        firstName: "Tobias",
        lastName: "Bayer",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1999-11-17",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      19254: {
        id: 19254,
        startno: 191,
        firstName: "Amaury",
        lastName: "Capiot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-25",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      65604: {
        id: 65604,
        startno: 35,
        firstName: "Antoine",
        lastName: "Raugel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-02-14",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      3933: {
        id: 3933,
        startno: 17,
        firstName: "Matteo",
        lastName: "Trentin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-02",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      7730: {
        id: 7730,
        startno: 107,
        firstName: "Danny",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-07-26",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      38169: {
        id: 38169,
        startno: 165,
        firstName: "Johan",
        lastName: "Jacobs",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-03-01",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      22682: {
        id: 22682,
        startno: 32,
        firstName: "Oliver",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-09-16",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      96290: {
        id: 96290,
        startno: 223,
        firstName: "Milan",
        lastName: "Fretin",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2001-03-19",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      2326: {
        id: 2326,
        startno: 204,
        firstName: "Geoffrey",
        lastName: "Soupe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-03-22",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      20584: {
        id: 20584,
        startno: 121,
        firstName: "Alberto",
        lastName: "Bettiol",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      29: {
        id: 29,
        startno: 205,
        firstName: "Niki",
        lastName: "Terpstra",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1984-05-18",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      7964: {
        id: 7964,
        startno: 54,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      71213: {
        id: 71213,
        startno: 235,
        firstName: "Laurenz",
        lastName: "Rex",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-12-15",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      65025: {
        id: 65025,
        startno: 2,
        firstName: "Tom",
        lastName: "Pidcock",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-07-30",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      1310: {
        id: 1310,
        startno: 7,
        firstName: "Elia",
        lastName: "Viviani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-02-07",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      101713: {
        id: 101713,
        startno: 243,
        firstName: "Tord",
        lastName: "Gudmestad",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "2001-05-08",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      113439: {
        id: 113439,
        startno: 74,
        firstName: "Arnaud",
        lastName: "De Lie",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2002-03-16",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      16793: {
        id: 16793,
        startno: 61,
        firstName: "Mads",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-12-18",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      47830: {
        id: 47830,
        startno: 25,
        firstName: "Jannik",
        lastName: "Steimle",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1996-04-04",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      37325: {
        id: 37325,
        startno: 134,
        firstName: "Kevin",
        lastName: "Geniets",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1997-01-09",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      65580: {
        id: 65580,
        startno: 93,
        firstName: "Filip",
        lastName: "Maciejuk",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1999-09-03",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      18079: {
        id: 18079,
        startno: 172,
        firstName: "Alex",
        lastName: "Edmondson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-12-22",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      16581: {
        id: 16581,
        startno: 142,
        firstName: "Aim\u00c3\u00a9",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-06-17",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      46167: {
        id: 46167,
        startno: 162,
        firstName: "I\u00c3\u00b1igo",
        lastName: "Elosegui",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1998-03-06",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      9349: {
        id: 9349,
        startno: 103,
        firstName: "Jonas",
        lastName: "Koch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-25",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      97547: {
        id: 97547,
        startno: 46,
        firstName: "Olav",
        lastName: "Kooij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-10-17",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      9234: {
        id: 9234,
        startno: 175,
        firstName: "Luka",
        lastName: "Mezgec",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1988-06-27",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      10971: {
        id: 10971,
        startno: 21,
        firstName: "Yves",
        lastName: "Lampaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-10",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      1966: {
        id: 1966,
        startno: 211,
        firstName: "Jens",
        lastName: "Debusschere",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-08-28",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      10871: {
        id: 10871,
        startno: 135,
        firstName: "Olivier",
        lastName: "Le Gac",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-08-27",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      20581: {
        id: 20581,
        startno: 83,
        firstName: "Leonardo",
        lastName: "Basso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-12-25",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      10802: {
        id: 10802,
        startno: 146,
        firstName: "Taco",
        lastName: "van der Hoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-04",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      95151: {
        id: 95151,
        startno: 65,
        firstName: "Quinn",
        lastName: "Simmons",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2001-05-08",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      37427: {
        id: 37427,
        startno: 37,
        firstName: "Stan",
        lastName: "Dewulf",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-12-20",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      64654: {
        id: 64654,
        startno: 226,
        firstName: "Ward",
        lastName: "Vanhoof",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-04-18",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      49901: {
        id: 49901,
        startno: 215,
        firstName: "Victor",
        lastName: "Koretzky",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-08-26",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      71333: {
        id: 71333,
        startno: 183,
        firstName: "Alberto",
        lastName: "Dainese",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-03-25",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      407: {
        id: 407,
        startno: 14,
        firstName: "Vegard Stake",
        lastName: "Laengen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1989-02-07",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      20945: {
        id: 20945,
        startno: 67,
        firstName: "Otto",
        lastName: "Vergaerde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-07-15",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      3199: {
        id: 3199,
        startno: 153,
        firstName: "Hugo",
        lastName: "Houle",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-09-27",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      63055: {
        id: 63055,
        startno: 97,
        firstName: "Fred",
        lastName: "Wright",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-06-13",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      23372: {
        id: 23372,
        startno: 87,
        firstName: "Artyom",
        lastName: "Zakharov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1991-10-27",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      77596: {
        id: 77596,
        startno: 247,
        firstName: "S\u00c3\u00b8ren",
        lastName: "W\u00c3\u00a6renskjold",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "2000-03-12",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      6228: {
        id: 6228,
        startno: 77,
        firstName: "Tim",
        lastName: "Wellens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-10",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      38481: {
        id: 38481,
        startno: 166,
        firstName: "Mathias",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-05-05",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      46161: {
        id: 46161,
        startno: 126,
        firstName: "Jonas",
        lastName: "Rutsch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-24",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      45433: {
        id: 45433,
        startno: 42,
        firstName: "David",
        lastName: "Dekker",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-02",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      14325: {
        id: 14325,
        startno: 16,
        firstName: "Rui",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1996-09-05",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      9894: {
        id: 9894,
        startno: 55,
        firstName: "Michael",
        lastName: "Gogl",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-11-04",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      16787: {
        id: 16787,
        startno: 152,
        firstName: "Jenthe",
        lastName: "Biermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-30",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      77597: {
        id: 77597,
        startno: 195,
        firstName: "Markus",
        lastName: "Pajur",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "2000-09-23",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      16803: {
        id: 16803,
        startno: 15,
        firstName: "Oliviero",
        lastName: "Troia",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-01",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      63685: {
        id: 63685,
        startno: 62,
        firstName: "Daan",
        lastName: "Hoole",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-02-22",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      12493: {
        id: 12493,
        startno: 136,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-09-03",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      150: {
        id: 150,
        startno: 31,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      66276: {
        id: 66276,
        startno: 222,
        firstName: "Gilles",
        lastName: "De Wilde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-10-12",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      46959: {
        id: 46959,
        startno: 196,
        firstName: "Cl\u00c3\u00a9ment",
        lastName: "Russo",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-01-20",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      80489: {
        id: 80489,
        startno: 94,
        firstName: "Jonathan",
        lastName: "Milan",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-10-01",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      31208: {
        id: 31208,
        startno: 187,
        firstName: "Cees",
        lastName: "Bol",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-07-27",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      28169: {
        id: 28169,
        startno: 53,
        firstName: "Dries",
        lastName: "De Bondt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-07-04",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      865: {
        id: 865,
        startno: 234,
        firstName: "Timothy",
        lastName: "Dupont",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-11-01",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      45620: {
        id: 45620,
        startno: 12,
        firstName: "Alexys",
        lastName: "Brunel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-10-10",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      40385: {
        id: 40385,
        startno: 237,
        firstName: "Attilio",
        lastName: "Viviani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-10-18",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      27230: {
        id: 27230,
        startno: 241,
        firstName: "Kristoffer",
        lastName: "Halvorsen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-04-13",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      69912: {
        id: 69912,
        startno: 73,
        firstName: "Florian",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-03-12",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      66265: {
        id: 66265,
        startno: 13,
        firstName: "Mikkel",
        lastName: "Bjerg",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-11-03",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      77493: {
        id: 77493,
        startno: 3,
        firstName: "Kim",
        lastName: "Heiduk",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-03-03",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      8740: {
        id: 8740,
        startno: 64,
        firstName: "Alex",
        lastName: "Kirsch",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-06-12",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      55141: {
        id: 55141,
        startno: 173,
        firstName: "Campbell",
        lastName: "Stewart",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-05-12",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      90911: {
        id: 90911,
        startno: 154,
        firstName: "Taj",
        lastName: "Jones",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "2000-07-26",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      4927: {
        id: 4927,
        startno: 115,
        firstName: "Kenneth",
        lastName: "Vanbilsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-06-01",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      11828: {
        id: 11828,
        startno: 161,
        firstName: "Iv\u00c3\u00a1n",
        lastName: "Garc\u00c3\u00ada Cortina",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-11-20",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      65461: {
        id: 65461,
        startno: 185,
        firstName: "Niklas",
        lastName: "M\u00c3\u00a4rkl",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-03-03",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      11313: {
        id: 11313,
        startno: 45,
        firstName: "Mike",
        lastName: "Teunissen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-08-25",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      3050: {
        id: 3050,
        startno: 92,
        firstName: "Jan",
        lastName: "Tratnik",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1990-02-23",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      8388: {
        id: 8388,
        startno: 106,
        firstName: "Lukas",
        lastName: "P\u00c3\u00b6stlberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1992-01-10",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      16789: {
        id: 16789,
        startno: 207,
        firstName: "Dries",
        lastName: "van Gestel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-30",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      37379: {
        id: 37379,
        startno: 133,
        firstName: "Valentin",
        lastName: "Madouas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-12",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      3096: {
        id: 3096,
        startno: 82,
        firstName: "Manuele",
        lastName: "Boaro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-12-03",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      312: {
        id: 312,
        startno: 125,
        firstName: "Sebastian",
        lastName: "Langeveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-01-17",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      102733: {
        id: 102733,
        startno: 4,
        firstName: "Magnus",
        lastName: "Sheffield",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2002-04-19",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "DDV"
  const raceID = 75

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
